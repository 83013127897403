<template>
  <canvas :height="height || '200px'" :width="width" :id="this.id"></canvas>
</template>

<script>
import Chart from "chart.js";
export default {
  props: ["id", "type", "labels", "dataChart", "height", "description", "subtitlePosition", "dark", "backgroundColor", "width"],
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    dados() {
      this.montaChart();
    }
  },
  methods: {
    unmountChart() {
      this.chart.destroy();
    },
    montaChart() {
      var tela = document.getElementById(this.id).getContext("2d");
      this.chart = new Chart(tela, {
        type: this.type,
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.description,
              backgroundColor: this.backgroundColor,
              borderColor: this.dark ? this.$theme.secondary : this.$theme.primary,
              data: this.dataChart
            },
          ]
        },
        options: {
          legend: {
            display: true,
            labels: {
              fontColor: this.dark ? '#fff' : "#333",
            },
            position: this.subtitlePosition || 'right' // Coloque a legenda na parte inferior
          },
          responsive: true,
        },
      });
    }
  },
  mounted() {
    this.montaChart();
  }
};
</script>
